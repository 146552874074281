<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 618px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">{{ this.messagetype.title_jp }}<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{ name: 'Dashboard' }"> ダッシュボード </router-link>
            </li>

            <li>
              <span>{{ this.messagetype.title_jp }}</span>
            </li>
          </ul>
        </nav>
      </div>

      <div v-if="messagecolumn['admin_create']" class="command_wrap">
        <div class="leftarea btn_wrap">
          <div class="btn_norm">
            <router-link
              :to="{
                name: 'Admin messagecreate',
                params: { type: message_type },
              }"
            >
              {{ messagecolumn["admin_create"] }}
            </router-link>
          </div>
        </div>
        <!--leftarea-->
        <div class="rightarea"></div>
        <!--rightarea-->
      </div>
      <!--command_wrap-->

      <!-- <Delete
        :title="'確認'"
        :message="'アーカイブに移動しますか？'"
        :model="conts.pagination_url"
      /> -->

      <!--modal-->
      <!-- <SuccessMessage v-if="successMessage" /> -->

      <div class="contentout_wrap">
        <div class="content_wrap">
          <div class="box_wrap">
            <div class="box_header">
              <h2 class="ttl_2 left m_r20">
                {{ message_type == 'sdgsgoal' ? '目標' : messagetype.title_jp }}一覧
              </h2>
              <div class="form_outwrap left" v-if="this.message_type == 'sdgss'">
                <div class="wrap wid_200px m_b0" >
                  <div class="in_wrap">
                    <div class="select_wrap min">
                      <select
                        name="list_type_select"
                        v-model="finYear"
                        @change="changeYear($event)"
                      >
                        <option value="">すべて</option>
                        <!-- <option value="2021">49期（2021）</option> -->
                        <option value="2022">49期（2022）</option>
                        <option value="2023">50期（2023）</option>
                        <option value="2024">51期（2024）</option>
                        <option value="2025">52期（2025）</option>
                        <option value="2026">53期（2026）</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="wrap wid_200px m_b0">
                  <div class="in_wrap">
                    <div class="select_wrap min">
                      <select
                        name="list_type_select"
                        v-model="pageParam.searchMonth"
                        @change="changeMonth($event)"
                      >
                        <option value="" :data-year="finYear">
                          通年
                          {{ finYear != "" ? "(" + finYear + ")" : "" }}
                        </option>
                        <option
                          value="10"
                          :data-year="
                            finYear - 1 != '' && finYear != 0 ? finYear - 1 : ''
                          "
                        >
                          10月
                          {{
                            finYear - 1 != "" && finYear != 0
                              ? "(" + (finYear - 1) + ")"
                              : ""
                          }}
                        </option>
                        <option
                          value="11"
                          :data-year="
                            finYear - 1 != '' && finYear != 0 ? finYear - 1 : ''
                          "
                        >
                          11月
                          {{
                            finYear - 1 != "" && finYear != 0
                              ? "(" + (finYear - 1) + ")"
                              : ""
                          }}
                        </option>
                        <option
                          value="12"
                          :data-year="
                            finYear - 1 != '' && finYear != 0 ? finYear - 1 : ''
                          "
                        >
                          12月
                          {{
                            finYear - 1 != "" && finYear != 0
                              ? "(" + (finYear - 1) + ")"
                              : ""
                          }}
                        </option>
                        <option value="1" :data-year="finYear">
                          1月
                          <!-- change financial year mismatch -->
                          {{ finYear != "" ? "(" + finYear + ")" : "" }}
                        </option>
                        <option value="2" :data-year="finYear">
                          2月
                          {{ finYear != "" ? "(" + finYear + ")" : "" }}
                        </option>
                        <option value="3" :data-year="finYear">
                          3月
                          {{ finYear != "" ? "(" + finYear + ")" : "" }}
                        </option>
                        <option value="4" :data-year="finYear">
                          4月
                          {{ finYear != "" ? "(" + finYear + ")" : "" }}
                        </option>
                        <option value="5" :data-year="finYear">
                          5月
                          {{ finYear != "" ? "(" + finYear + ")" : "" }}
                        </option>
                        <option value="6" :data-year="finYear">
                          6月
                          {{ finYear != "" ? "(" + finYear + ")" : "" }}
                        </option>
                        <option value="7" :data-year="finYear">
                          7月
                          {{ finYear != "" ? "(" + finYear + ")" : "" }}
                        </option>
                        <option value="8" :data-year="finYear">
                          8月
                          {{ finYear != "" ? "(" + finYear + ")" : "" }}
                        </option>
                        <option value="9" :data-year="finYear">
                          9月
                          {{ finYear != "" ? "(" + finYear + ")" : "" }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="form_outwrap left" v-else-if="this.message_type != 'mysdgs'">
                <div class="wrap wid_200px m_b0">
                  <div class="in_wrap">
                    <div class="select_wrap min" v-if="this.message_type != 'sdgsgoal'">
                      <select @change="StatusChange($event)" name="list_type_select">
                        <option value="all">すべて</option>
                        <option value="下書き">下書き</option>
                        <option value="公開">公開</option>
                        <option
                          v-if="messagecolumn['status_list'] == '承認'"
                          value="申請中"
                        >
                          申請中
                        </option>
                        <option
                          v-if="messagecolumn['status_list'] == '承認'"
                          value="承認済"
                        >
                          承認済
                        </option>
                        <option
                          v-if="messagecolumn['status_list'] == '承認'"
                          value="却下"
                        >
                          却下
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="form_outwrap right"
                v-if="
                  this.message_type == 'thanks' ||
                  this.message_type == 'sdgs' ||
                  this.message_type == 'challenge' ||
                  this.message_type == 'idea' ||
                  this.message_type == 'sdgsgoal' ||
                  this.message_type == 'mysdgs'
                "
              >
                <div class="wrap wid_200px m_b0">
                  <div class="in_wrap right">
                    <div class="btn_mic form_btn csv_btn right">
                      <button
                        @click="exportCsv()"
                        style="cursor: pointer; margin: 10px"
                        type="submit"
                      >
                        CSV出力
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tool_wrap">
              <div class="bulktool">
                <div class="m_r50">
                  <span class="selectednum"></span><span>が選択済みです</span>
                </div>

                <div class="delete commandtext js-modal-open">
                  <button value="アーカイブ" class="" dusk="delete_data">削除</button>
                </div>
              </div>
              <div id="drawer_bg" class="show"></div>
              <div class="command_wrap_inbox">
                <div class="leftarea">
                  <div class="search_button_wrap" id="dsplanonepc">
                    <button class="button_search" @click="getSearchData()">
                      検索<i class="fas fa-search"></i>
                    </button>
                  </div>
                  <div class="wrap m_b10">
                    <div class="seach_contents">
                      <div class="search_box reverse sp_none">
                        <div class="search_wrap">
                          <div class="input_box">
                            <label
                              ><!--title--><input
                                type="text"
                                name="titlebody"
                                class="form_dsn"
                                id="searchInput"
                                autocomplete="off"
                                placeholder="タイトル 本文"
                                v-model="serachData.typeContent"
                            /></label>
                          </div>
                          <div class="megamenu b_350" style="display: none">
                            <div class="in_wrap">
                              <p id="megamenu_btn" class="remove_btn tooltip tip-top">
                                <i class="fas fa-times"></i>
                              </p>
                              <div class="flex_wrap">
                                <table class="formtable">
                                  <tbody>
                                    <tr>
                                      <th class="wid_20per v_m">ユーザー</th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap">
                                              <div class="select_wrap">
                                                <select
                                                  name="update_user_id"
                                                  v-model="serachData.user"
                                                >
                                                  <option value="未選択">未選択</option>
                                                  <option
                                                    v-for="userlist in this.userlists.slice()"
                                                    v-bind:value="userlist.id"
                                                    :key="userlist.id"
                                                  >
                                                    {{ userlist.last_name
                                                    }}{{ userlist.first_name }}
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th class="wid_20per v_m">ステータス</th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap">
                                              <div class="select_wrap">
                                                <select
                                                  name="status"
                                                  v-model="serachData.status"
                                                >
                                                  <option value="all">未選択</option>
                                                  <option value="公開">公開</option>
                                                  <option value="下書き">下書き</option>
                                                  <option
                                                    v-if="
                                                      messagecolumn['status_list'] ==
                                                      '承認'
                                                    "
                                                    value="申請中"
                                                  >
                                                    申請中
                                                  </option>
                                                  <option
                                                    v-if="
                                                      messagecolumn['status_list'] ==
                                                      '承認'
                                                    "
                                                    value="承認済"
                                                  >
                                                    承認済
                                                  </option>
                                                  <option
                                                    v-if="
                                                      messagecolumn['status_list'] ==
                                                      '承認'
                                                    "
                                                    value="却下"
                                                  >
                                                    却下
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr v-if="message_type == 'daily'">
                                      <th class="wid_20per v_m">タグ</th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap">
                                              <div class="select_wrap">
                                                <select
                                                  name="tags"
                                                  v-model="serachData.tags"
                                                >
                                                  <option value="all">未選択</option>
                                                  <option
                                                    v-for="taglist in this.taglists.slice()"
                                                    :key="taglist.id"
                                                    v-bind:value="taglist.id"
                                                  >
                                                    {{ taglist.name }}
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th class="wid_20per v_m">公開日</th>
                                      <td class="ds_b">
                                        <div class="form_outwrap">
                                          <div class="wrap wid_100per m_b0">
                                            <div class="in_wrap flex_wrap f_sp">
                                              <div class="wrap wid_50per m_b0 time_wrap">
                                                <label class="ttl_3"
                                                  >開始日

                                                  <input
                                                    name="public_start"
                                                    type="date"
                                                    placeholder="YYYY/MM/DD"
                                                    class="input-date publish_start"
                                                    autocomplete="off"
                                                    v-model="serachData.published_start"
                                                  />
                                                </label>
                                              </div>
                                              <div class="wrap wid_50per m_b0 time_wrap">
                                                <label class="ttl_3"
                                                  >終了日
                                                  <input
                                                    name="public_end"
                                                    type="date"
                                                    placeholder="YYYY/MM/DD"
                                                    class="input-date publish_end"
                                                    autocomplete="off"
                                                    v-model="serachData.published_end"
                                                  />
                                                </label>
                                              </div>
                                            </div>
                                            <div
                                              class="msg_wrap"
                                              v-if="publishedStartValidated"
                                            >
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation
                                                    .start_date_format
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>
                                            <div
                                              class="msg_wrap"
                                              v-if="publishedEndValidated"
                                            >
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation.end_date_format
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>

                                            <div class="msg_wrap" v-if="startGreatThen">
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation
                                                    .start_date_limit
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>
                                            <div class="msg_wrap" v-if="endLessThen">
                                              <span class="each_msg"
                                                >{{
                                                  conts.search_validation.end_date_limit
                                                }}<span class="trg"></span
                                              ></span>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="flex_wrap f_e">
                                <div class="btn_min">
                                  <button @click="inputSearch()">検索</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="search_button">
                          <button @click="inputSearch()">
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                      <div class="in_wrap">
                        <div class="flex_wrap">
                          <ul class="count_list">
                            <!-- <li v-if="pagination.total > 0">
                              <span>検索結果：{{ pagination.total }} </span>
                            </li> -->
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--wrap-->
                </div>
                <!--leftarea-->
                <div class="rightarea">
                  <ul v-if="total > get_post_num" class="pagination" role="navigation">
                    <li
                      class="page-item"
                      style="cursor: pointer"
                      v-bind:class="[pagination.data.currentPage == 1 ? 'disabled' : '']"
                      @click="setPage(pagination.data.currentPage - 1)"
                      rel="prev"
                      aria-label="« 前へ"
                    >
                      <span class="page-link" aria-hidden="true">‹</span>
                    </li>
                    <li
                      style="cursor: pointer"
                      class="page-item"
                      aria-current="page"
                      v-for="n in pagination.data.pages"
                      :key="n.index"
                      v-bind:class="[pagination.data.currentPage == n ? 'active' : '']"
                      @click="setPage(n)"
                    >
                      <span class="page-link">{{ n }}</span>
                    </li>
                    <li
                      style="cursor: pointer"
                      class="page-item"
                      aria-label="次へ »"
                      @click="setPage(pagination.data.currentPage + 1)"
                      v-bind:class="[
                        pagination.data.currentPage == pagination.data.endPage
                          ? 'disabled'
                          : '',
                      ]"
                    >
                      <span class="page-link" aria-hidden="true">›</span>
                    </li>
                  </ul>
                </div>
                <!--rightarea-->
              </div>
              <!--command_wrap-->
            </div>
            <!--tool_wrap-->
            <form id="">
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <h2 style="display: none" class="ttl_2 left m_r20">サンクス集計</h2>
                  <table
                    v-if="message_type == 'thanks'"
                    class="tbl"
                    style="display: none"
                  >
                    <tbody>
                      <tr>
                        <th class="wid_30per t_l">ユーザー名</th>
                        <th class="dtheader wid_15per">部門</th>
                        <th class="command wid_10per">書いた数</th>
                        <th class="wid_15per">もらった数</th>
                      </tr>

                      <!-- <tr class="nopublic"> -->
                      <tr v-for="thanks in thankMembers" :key="thanks.id">
                        <td class="v_m p_t15 p_b15">
                          <span>
                            {{ thanks.from_user ? thanks.from_user.last_name : "" + " " }}
                            {{ thanks.from_user ? thanks.from_user.first_name : "" }}
                          </span>
                        </td>
                        <td class="t_c v_m">
                          <span>{{
                            thanks.from_user
                              ? thanks.from_user.group
                                ? thanks.from_user.group.name
                                : ""
                              : ""
                          }}</span>
                        </td>
                        <td class="t_c v_m">
                          <span>{{ thanks.count }}</span>
                        </td>
                        <td class="t_c v_m">
                          <span>{{ thanks.target_count }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- <br /><br />
                  <br /><br />
                  <br /><br /> -->

                  <table class="tbl">
                    <tbody>
                      <tr>
                        <th class="chkarea">
                          <label class="checkbox_wrap" dusk="checkall">
                            <input
                              type="checkbox"
                              name=""
                              value=""
                              class="chkbox allchk allcheckbox"
                            /><span></span>
                          </label>
                        </th>
                        <th 
                          :class="this.message_type == 'sdgsgoal' ? 'wid_20per' : 'wid_30per'"
                          class="t_l"
                        >
                        {{this.message_type == 'sdgsgoal' ? '期' : 'タイトル'}}
                        </th>                 
                        <th
                          v-if="messagecolumn['public'] && message_type != 'thanks'"
                          class="wid_15per"
                        >
                          {{ messagecolumn["public"] }}
                        </th>
                        <th v-if="messagecolumn['group']" class="wid_10per">
                          {{ messagecolumn["group"] }}
                        </th>
                        <th class="wid_10per" v-if="messagecolumn['target_ids']">
                          {{ messagecolumn["target_ids"] }}
                        </th>
                        <template v-if="this.message_type == 'sdgsgoal'">
                          <th class="wid_20per">
                            通期(実績/目標)
                          </th>
                          <th class="wid_20per" v-for="column, index in sdgsGoals.columns" :key="index">
                            {{ column }}(実績/目標)
                          </th>
                        </template>
                        
                        <template v-else>
                          <template v-if="this.message_type == 'mysdgs'">
                            <th class="wid_15per">対応目標</th>
                            <th class="dtheader wid_15per">部門</th>
                          </template>
                          <th class="wid_15per">作成者</th>
                          <th class="dtheader wid_15per">投稿日</th>
                          <template v-if="this.message_type != 'mysdgs'">
                            <th class="dtheader wid_15per">公開日</th>
                            <th class="command wid_10per">公開状況</th>
                          </template>
                        </template>
                        <th class="wid_15per"></th>
                      </tr>

                      <!-- <tr class="nopublic"> -->
                      <tr v-if="isTableDataLoader">
                      <td colspan="9" >
                         <div class="spinner-style">
                            <div class="half-circle-spinner">
                              <div class="circle circle-1"></div>
                              <div class="circle circle-2"></div>
                            </div>
                        </div>                      
                      </td>
                     </tr>
                      <tr
                        v-for="message in messages.slice()"
                        :key="message.id"
                        v-bind:class="[message.status == '下書き' ? 'nopublic' : '']"
                        v-else
                      >
                        <td class="t_c v_m">
                          <label class="checkbox_wrap" dusk="テスト入力">
                            <input
                              type="checkbox"
                              name="status_chg[]"
                              v-bind:value="message.id"
                              class="chkbox"
                              @change="delete_changed(message.id)"
                            /><span></span>
                          </label>
                        </td>
                        <td class="v_m p_t15 p_b15">
                          <router-link
                            v-if="message_type == 'thanks'"
                            :to="{
                              name: 'Admin messageshow',
                              params: { type: message_type, id: message.id },
                            }"
                          >
                            <img
                              v-if="message.target_user.length <= 0"
                              src="https://www.kdfc.com/wp-content/themes/uscradio/dist/images/spinner-1.gif"
                              width="25"
                              height="25"
                              style="margin-right: 10px"
                            />

                            <template v-else>
                              <span v-for="user in message.target_user" :key="user.id">                                
                                <!-- {{ user.last_name }} {{ user.first_name }}さん -->  <!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                                {{ user.deleted_at ?  user.last_name + user.first_name +' (削除済み) ': user.last_name + user.first_name }}さん
                              </span>へのサンクスカード
                            </template>
                          </router-link>
                          <router-link
                            v-else-if="
                              message.type == 'challenge' || message.type == 'sdgs'
                            "
                            :to="{
                              name: 'Admin messageshow',
                              params: { type: message_type, id: message.id },
                            }"
                          >
                            {{ showYear(message.option) }}
                          </router-link>
                          <router-link
                            v-else-if="
                              message.type == 'idea' ||
                              message.type == 'daily' ||
                              message.type == 'photolog'
                            "
                            :to="{
                              name: 'Admin messageshow',
                              params: { type: message_type, id: message.id },
                            }"
                            v-html="message.content"
                          ></router-link>
                          <router-link
                            v-else-if="
                              message.type == 'sdgsgoal'
                            "
                            :to="{
                              name: 'Admin messageshow',
                              params: { type: message_type, id: message.id },
                            }"
                          >{{ message.option }}</router-link>
                          
                          <router-link
                            v-else
                            :to="{
                              name: 'Admin messageshow',
                              params: { type: message_type, id: message.id },
                            }"
                            >{{ message_type == 'mysdgs' ? message.content : message.title }}
                          </router-link>
                        </td>
                        <td
                          v-if="messagecolumn['public'] && message_type != 'thanks'"
                          class="t_c v_m"
                        >
                          <span v-if="message.public">{{ message.public }}</span>
                        </td>
                        <td v-if="messagecolumn['group']" class="t_c v_m">
                          <!-- <span v-if="message.group">{{ message.group }}</span> -->
                          <span v-if="!(message.group) && messagecolumn['photolog']">{{ message.deparment }}</span>
                          <span v-if="(message.group)  && messagecolumn['photolog']">{{ message.group }}</span>
                          <span v-if="message.group && !(messagecolumn['photolog'])">{{ message.group }}</span>
                        </td>
                        <td v-if="messagecolumn['target_ids']" class="t_c v_m">
                          <!--https://connectfactory.atlassian.net/browse/OTHER-5059-->
                          <img
                            v-if="message.target_user.length <= 0"
                            src="../../../assets/images/spinner/spinner.gif"
                            width="25"
                            height="25"
                            style="margin-right: 10px"
                          />
                          <template v-else>
                            <span v-for="user in message.target_user" :key="user.id"
                            
                              >
                              <!-- {{ user.last_name }} {{ user.first_name }} --><!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                              {{ user.deleted_at ?  user.last_name + user.first_name +' (削除済み) ': user.last_name + user.first_name }}</span
                            >
                          </template>
                        </td>
                        <template v-if="this.message_type == 'sdgsgoal'">
                          <td class="t_c v_m">
                            {{ message.longTermReportCount + '/' + message.totalLongTermGoal }} 件
                          </td>
                          <td class="t_c v_m" v-for="month, index in message.months" :key="index">
                            {{ month.reportCount + '/' + month.goal }} 件
                          </td>
                        </template>

                        <template v-if="this.message_type == 'mysdgs'">
                          <td class="t_c v_m">
                            {{message.goalType}}
                          </td>
                          <td class="t_c v_m">
                            {{message.group}}
                          </td>
                          
                        </template>

                        <template v-if="this.message_type !== 'sdgsgoal'">
                          <td class="t_c v_m">
                            <span v-if="message.from_user != null"
                              >
                              <!-- {{ message.from_user.last_name }}  {{ message.from_user.first_name }} --><!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                              {{ message.from_user.deleted_at ?  message.from_user.last_name + message.from_user.first_name +' (削除済み) ': message.from_user.last_name + message.from_user.first_name }}
                              </span
                            >
                            <span v-else style="opacity: 0.5"> 削除済ユーザー </span>
                          </td>
                          <td class="date t_r">
                            <time
                              class="en"
                              :datetime="formatDate_time(message.created_at)"
                              >{{ formatDate(message.created_at) }}</time
                            >
                          </td>
                          <template v-if="this.message_type != 'mysdgs'">
                            <td class="date">
                              <time
                                class="en"
                                :datetime="formatDate_time(message.published_at)"
                                >{{ formatDate(message.published_at) }}</time
                              >
                            </td>
                            <td class="t_c v_m">
                              <span>{{ message.status }}</span>
                            </td>
                          </template>
                        </template>
                        <td class="t_c v_m command_area">
                          <div class="commandlist">
                            <div class="txt">
                              <router-link
                                :to="{
                                  name: 'Admin messageshow',
                                  params: {
                                    type: message_type,
                                    id: message.id,
                                  },
                                }"
                              >
                                表示</router-link
                              >
                            </div>
                            <div
                              v-if="(messagecolumn['admin_edit'] && message.from_admin) || (this.message_type === 'sdgsgoal')"
                              class="txt"
                            >
                              <router-link
                                :to="{
                                  name: 'Admin messageedit',
                                  params: {
                                    type: message_type,
                                    id: message.id,
                                  },
                                }"
                              >
                                {{ messagecolumn["admin_edit"] }}</router-link
                              >
                            </div>
                            <div
                              v-if="messagecolumn['admin_edit'] && message.type == 'photolog'"
                              class="txt"
                            >
                              <router-link
                                :to="{
                                  name: 'Admin messageedit',
                                  params: {
                                    type: message_type,
                                    id: message.id,
                                  },
                                }"
                              >
                                {{ messagecolumn["admin_edit"] }}</router-link
                              >
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <p v-else>検索結果はありません</p> -->

                  <!--tbl-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </form>

            <div class="command_wrap_inbox m_t10 m_b30">
              <div class="rightarea">
                <ul v-if="total > get_post_num" class="pagination" role="navigation">
                  <li
                    class="page-item"
                    style="cursor: pointer"
                    v-bind:class="[pagination.data.currentPage == 1 ? 'disabled' : '']"
                    @click="setPage(pagination.data.currentPage - 1)"
                    rel="prev"
                    aria-label="« 前へ"
                  >
                    <span class="page-link" aria-hidden="true">‹</span>
                  </li>
                  <li
                    style="cursor: pointer"
                    class="page-item"
                    aria-current="page"
                    v-for="n in pagination.data.pages"
                    :key="n.index"
                    v-bind:class="[pagination.data.currentPage == n ? 'active' : '']"
                    @click="setPage(n)"
                  >
                    <span class="page-link">{{ n }}</span>
                  </li>
                  <li
                    style="cursor: pointer"
                    class="page-item"
                    aria-label="次へ »"
                    @click="setPage(pagination.data.currentPage + 1)"
                    v-bind:class="[
                      pagination.data.currentPage == pagination.data.endPage
                        ? 'disabled'
                        : '',
                    ]"
                  >
                    <span class="page-link" aria-hidden="true">›</span>
                  </li>
                </ul>
              </div>
              <!--rightarea-->
            </div>
            <!--command_wrap-->
          </div>
          <!--box_wrap-->
        </div>
        <!--content_wrap-->
      </div>
      <!--contentoutwrap-->
    </div>
    <!--maincontentinner-->
  </div>

  <div
    class="modal"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal_inner confirm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form_outwrap">
              <div class="modal_header">
                <p class="ttl_header">確認</p>
              </div>
              <div class="modal_wrap">
                <div class="for_wrap h_80">
                  <div class="modal_contents">
                    <p>
                      <span>削除しますか？</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal_footer">
                <div class="flex_wrap f_c">
                  <div class="btn_norm reverse" id="cancel_bk">
                    <button
                      name=""
                      value="キャンセル"
                      class="close"
                      dusk="delete_data"
                      @click="closeDiscard()"
                    >
                      キャンセル
                    </button>
                  </div>
                  <div class="btn_norm delete">
                    <button
                      @click="deleteConfirm()"
                      class="btn btn"
                      value="アーカイブ"
                      title="削除"
                      dusk="delete_data"
                    >
                      実行
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        id="close_btn"
        class="pop-remove_btn ttoltip tip-top close"
        @click="closeDiscard()"
      >
        <i class="fas fa-times"></i>
      </p>
      <!-- <div class="close_btn tooltip tip-left" title="閉じる">
				<i class="fas fa-times"></i>
			</div> -->
    </div>
    <!--modal_inner-->
  </div>
  <!--modal-->
</template>

<script>
import "@/assets/front_component/js/functions.js";
//import "@/assets/front_component/js/scroll-hint.min.js";
import message from "@/apis/Message";
import Group from "@/apis/Groups";
import $ from "jquery";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import dayjs from "dayjs";
import SDGSReport from "@/apis/SDGs";
import user from "@/apis/User";
import ScrollHint from "scroll-hint";
import striptags from "striptags";
import Thanks from "@/apis/Thanks";

export default {
  name: "message",
  data() {
    return {
      visiblePageCount: 8,
      lastEndPage: 1,
      serachData: {
        user: "未選択",
        type: "",
        status: "all",
        tags: "all",
        published_start: null,
        published_end: null,
      },
      userlists: [],
      daily_tag: null,
      taglists: "",
      messages: [],
      cretemessage: { target_ids: [] },
      nameError: "",
      emailError: "",
      loader: false,
      isTableDataLoader:true,
      showmessage: {},
      editmessage: {},
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      messagetype: {},
      now_post_num: 25,
      get_post_num: 25,
      total: "",
      order: "desc",
      status: "all",
      pagination: [],
      setDeleteId: [],
      delete_id: [],
      thanksCounts: [],
      finYear:
        new Date().getMonth() + 1 == 10 ||
        new Date().getMonth() + 1 == 11 ||
        new Date().getMonth() + 1 == 12
          ? new Date().getFullYear() + 1
          : new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      thankMembers: [],
      publishedStartValidated: false,
      publishedEndValidated: false,
      startGreatThen: false,
      endLessThen: false,
      pageParam: {
        pageNo: 1,
        perPageCount: 25,
        messageType: this.$route.params.type,
        searchYear: new Date().getFullYear(),
        searchMonth: new Date().getMonth() + 1,
        mySdgsSummary: [],
      },
      sdgsGoals: {
        columns: ['10~12月', '1~3月', '4~6月', '7~9月'],
      }
    };
  },
  components: {},
  watch: {
    $route(to, from) {
      if (from.name == "Admin message" && to.name == "Admin message") {
        $(function () {
          $("#js-loader").show();
          $(window).scrollTop(0);
        });
        this.message_type = this.$route.params.type;
        this.messagetype = MessageType[this.message_type];
        this.messagecolumn = MessageColumn[this.message_type];
        this.serachData.type = this.message_type;
        if (this.messagecolumn == null) {
          this.$router.push({ name: "Front 404" });
        }
        this.getUserLists();
        this.messageList(this.message_type, this.get_post_num, this.order, this.status);
        if (this.message_type == "daily") {
          this.getTagLists(this.messagecolumn["tag_list"]);
        }
        $(function () {
          $("#js-loader").delay(500).fadeOut(600);
        });
      }
    },
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = this.$route.params.type;
    this.messagetype = MessageType[this.message_type];
    this.messagecolumn = MessageColumn[this.message_type];
    this.serachData.type = this.message_type;
    console.log('index',this.messagecolumn);
    if (this.messagecolumn == null) {
      this.$router.push({ name: "Front 404" });
    }
    this.getUserLists();
    this.messageList(this.message_type, this.get_post_num, this.order, this.status);
    if (this.message_type == "daily") {
      this.getTagLists(this.messagecolumn["tag_list"]);
    }
  },
  methods: {
    delete_changed(id) {
      this.delete_id.push(id);
    },
    deleteConfirm() {
      this.delete_id.forEach((id) => {
        this.deletemessage(id);
      });
      $("#confirmModal").removeClass("open");
      $(".bulktool").removeClass("open");
      $(".command_wrap_inbox").removeClass("hide");
    },
    getUserLists() {
      user
        .list()
        .then((response) => {
          if (response != null) {
            this.userlists = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    
    changeYear: function (event) {

      if (event.target.value != "") {
        let currentMonth = this.pageParam.searchMonth;
        if (currentMonth == 10 || currentMonth == 11 || currentMonth == 12) {
          this.pageParam.searchYear = event.target.value - 1;
        } else {
          this.pageParam.searchYear = event.target.value;
        }
      } else {
        this.pageParam.searchYear = "";
      }

     this.messageList(this.message_type, this.get_post_num, this.order, this.status);

    },

    changeMonth: function (e) {

      this.pageParam.searchYear =
        e.target.options[e.target.options.selectedIndex].dataset.year;

      this.pageParam.searchMonth = e.target.value;

      this.messageList(this.message_type, this.get_post_num, this.order, this.status);


      if (this.pageParam.searchYear == new Date().getFullYear() - 1) {
        this.pageParam.searchYear = new Date().getFullYear();
      }
    },

    inputSearch() {
      var query =
        "type=" +
        this.serachData.type +
        "&" +
        this.order +
        "=created_at&per=" +
        this.get_post_num;

      if (this.serachData.user != null && this.serachData.user != "未選択") {
        query += "&from_id=" + this.serachData.user;
      }
      if (this.serachData.status != null && this.serachData.status != "all") {
        query += "&status=" + this.serachData.status;
      }

      if (this.serachData.tags != null && this.serachData.tags != "all") {
        console.log(this.serachData.tags);
        query += "&tags=" + this.serachData.tags;
      }

      if (
        this.serachData.published_start != null &&
        this.serachData.published_end != null
      ) {
        var d_1 = new Date(this.serachData.published_start);
        var s_1 = d_1.toISOString().substr(0, 19);
        this.serachData.published_start = s_1;

        var d_2 = new Date(this.serachData.published_end);
        var s_2 = d_2.toISOString().substr(0, 19);
        this.serachData.published_end = s_2.replace("T00:00:00", "T23:59:59");

        query +=
          "&datetime=published_at@between," +
          this.serachData.published_start +
          "/" +
          this.serachData.published_end;
      }

      if (this.serachData.typeContent != null) {
        query += "&keyword=title,content@" + this.serachData.typeContent;
      }
      this.messageListQuery(query, null);
    },
    setPage(page) {
      var query = this.pagination.links[page] ? this.pagination.links[page].url : "";

      var query_path = query ? query.substr(query.indexOf("?") + 1) : null;

      // code updated by Elavarasan for pagination rendering
      query_path =
        "where=type@" +
        this.message_type +
        "&desc=published_at&per=" +
        this.now_post_num +
        "&page=" +
        page;
      this.messageListQuery(query_path, page);
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    deletemessage(messageid) {
      message
        .delete(messageid)
        .then((response) => {
          if (response != null) {
            this.messageList(
              this.message_type,
              this.get_post_num,
              this.order,
              this.status
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getTagLists(tag_array) {
      message
        .tag_list(tag_array)
        .then((response) => {
          if (response != null) {
            this.taglists = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageList(type, num, order, status) {
      message
        .listNumAdmin(type, num, order, status, this.pageParam)
        .then(async (response) => {
          if (response != null) {
            this.messages = response.data.data;

            if (this.message_type === 'sdgsgoal') {
              let responseData = response.data.data;

              responseData.sort((a,b)=>{
                return JSON.parse(a.content).finYear - JSON.parse(b.content).finYear
              })
              this.messages = responseData;
            }

            this.pagination = response.data;

            this.pagination.data = this.paginate(
              response.data.total,
              this.pagination.current_page,
              25,
              5
            );

            this.total = response.data.total;

            this.messages.forEach((message_single) => {
              message_single.group = message_single.deparment;

              message_single.content = striptags(message_single.content);
              message_single.content = message_single.content.replace(/&nbsp;/g, "");

              if (this.message_type == "sdgs" || this.message_type == "challenge" || this.message_type == "sdgsgoal" || this.message_type == "mysdgs") {
                if (this.IsJsonString(message_single.content)) {
                  let contentSplit = JSON.parse(message_single.content);
                  
                  if (this.message_type == "sdgsgoal") {
                    message_single.months = contentSplit.months
                    message_single.totalLongTermGoal = contentSplit.content
                    message_single.option = contentSplit.option
                    message_single.fiscalYear = contentSplit.finYear

                    SDGSReport.sdgsReportCount(contentSplit.finYear).then((res)=>{
                      if (res.data) {
                        message_single.longTermReportCount = res.data.long_term_count
  
                        let reportCount = 0;
                        message_single.months.forEach((el) => {
                          reportCount = res.data.short_term_count.find(obj => obj.name === el.name).count;
                          el.reportCount = reportCount
                        });
                      }else{
                        this.$router.push({ name: "Front Error Catch" });
                      }
                    }).catch((err)=>{
                      console.error(err);
                      this.$router.push({ name: "Front Error Catch" });
                    })
                    
                  }else{
                    message_single.content = striptags(contentSplit.content);
                    message_single.content_1 = striptags(contentSplit.content_1);
                    message_single.content_2 = striptags(contentSplit.content_2);
                    message_single.option = contentSplit.option;
                    message_single.month = contentSplit?.month;
                    if (this.message_type == "mysdgs") {
                      message_single.goalType = contentSplit.goalType;
                      message_single.content = message_single.content.replace(/&nbsp;/g, '')
                    }
                  }

                } else {
                  message_single.option = striptags(message_single.content);
                }
              } else {
                message_single.content = striptags(message_single.content);
              }

              if (message_single.target_ids != null) {
                message_single.target_user = [];
                message_single.target_ids.forEach((user_id) => {
                  user
                    .show(user_id)
                    .then((response) => {
                      if (response != null) {
                        message_single.target_user.push(response.data.data);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                });
              }

              if (message_single.from_id != null) {
                message_single.from_user = [];
                user
                  .show(message_single.from_id)
                  .then(async (response) => {
                    if (response != null) {
                      message_single.from_user = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              }
              // 管理画面からの投稿か取得
              if (this.messagecolumn["admin_edit"]) {
                message
                  .showmetakey(message_single.id, "key", "from_admin")
                  .then((response) => {
                    if (response.data.data.length > 0) {
                      message_single.from_admin = response.data.data[0].value;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              }

              // thanks_type取得
              if (this.messagecolumn["public"]) {
                message
                  .showmetakey(message_single.id, "key", "public")
                  .then((response) => {
                    if (response.data.data.length > 0) {
                      message_single.public = response.data.data[0].value;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
          this.isTableDataLoader = false;
        });
    },
    IsJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    messageListQuery(query, page = null) {
      message
        .listQuery(query)
        .then((response) => {
          if (response != null) {
            this.messages = response.data.data;

            if (this.message_type === 'sdgsgoal') {
              let responseData = response.data.data;

              responseData.sort((a,b)=>{
                return JSON.parse(a.content).finYear - JSON.parse(b.content).finYear
              })
              this.messages = responseData;
            }

            this.pagination = response.data;

            this.pagination.data = this.paginate(
              response.data.total,
              this.pagination.current_page,
              25,
              5
            );

            this.messages.forEach((message_single) => {

              if(this.message_type == "mysdgs") {
                message_single.content = JSON.parse(striptags(message_single.content))
                message_single.goalType = message_single.content.goalType;
                message_single.content = message_single.content.content.replace(/&nbsp;/g, '')
              }else{
                message_single.content = striptags(message_single.content);
                message_single.content = message_single.content.replace(/&nbsp;/g, "");
  
                if (this.message_type == "sdgsgoal") {
                  let contentSplit = JSON.parse(message_single.content);
                    message_single.months = contentSplit.months
                    message_single.goal = contentSplit.content
                    message_single.option = contentSplit.option
                    message_single.fiscalYear = contentSplit.finYear
                }
              }

              if (message_single.target_ids != null) {
                message_single.target_user = [];
                message_single.target_ids.forEach((user_id) => {
                  user
                    .show(user_id)
                    .then((response) => {
                      if (response != null) {
                        message_single.target_user.push(response.data.data);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {
                      this.loader = false;
                    });
                });
              }

              if (message_single.from_id != null) {
                message_single.from_user = [];
                user
                  .show(message_single.from_id)
                  .then((response) => {
                    if (response != null) {
                      message_single.from_user = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              }

              // thanks_type取得
              if (this.messagecolumn["public"]) {
                message
                  .showmetakey(message_single.id, "key", "public")
                  .then((response) => {
                    if (response.data.data.length > 0) {
                      message_single.public = response.data.data[0].value;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              }

              // 部門取得
              if (this.messagecolumn["group"]) {
                // loadDepartment Group
                message
                  .showmetakey(message_single.id, "key", "group")
                  .then((response) => {
                    if (response.data.data.length > 0) {
                      message_single.group = response.data.data[0].value;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    onOrderChange(event_order) {
      this.order = event_order.target.value;
      this.messageList(this.message_type, this.get_post_num, this.order, this.status);
    },
    StatusChange(event_status) {
      this.status = event_status.target.value;
      this.messageList(this.message_type, this.get_post_num, this.order, this.status);
    },
    closeDiscard() {
      $("#confirmModal").removeClass("open");
    },
    showYear(year) {
      if (typeof year == "number") {
        year = year.toString();
      }

      let setYear = "";
      switch (year) {
        case "2022":
          setYear = "49期 (2022)";
          break;
        case "2023":
          setYear = "50期 (2023)";
          break;
        case "2024":
          setYear = "51期 (2024)";
          break;
        case "2025":
          setYear = "52期 (2025)";
          break;
        case "2026":
          setYear = "53期 (2026)";
          break;
        default:
          setYear = year;

          break;
      }

      return setYear;
    },
    paginate(totalItems = null, currentPage = 1, pageSize = 25, maxPages = 5) {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);

      // ensure current page isn't out of range
      if (currentPage < 1) {
        currentPage = 1;
      } else if (currentPage > totalPages) {
        currentPage = totalPages;
      }

      let startPage, endPage;

      if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - maxPages + 1;
          endPage = totalPages;
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize, totalItems);
      // let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
      );

      // return object with all pager properties required by the view
      return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages,
      };
    },
    exportCsv() {
      let title = "タカヤマ";

      switch (this.message_type) {
        case "thanks":
          title = "サンクス";
          break;
        case "sdgs":
          title = "SDGs個人目標";

          break;
        case "challenge":
          title = "チャレンジ";

          break;
        case "mysdgs":
          title = "SDGsレポート";

          break;
        case "idea":
          title = "創発";

          break;
        case "sdgsgoal":
          title = "SDGs 全社目標";
          break;
        default:
          break;
      }

      if(MessageColumn[this.message_type]["csv"] === true) {
        return message.messageReport(this.pageParam)
          .then(async (response) => {
            if (response != "") {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", title + ".csv"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch((error) => {})
          .finally(() => {});
      }
      else {
        return Thanks.messageReport(this.pageParam)
          .then(async (response) => {
            if (response != "") {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", title + ".csv"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch((error) => {})
          .finally(() => {});
      }
    },
  },
  mounted() {
    var vueThis = this;
    $(function () {
      $(".js-modal-open").on("click", function () {
        var cntall = $(".allchk:checked").length;
        var cnt = $(".tbl input:checkbox:checked").length;
        this.setDeleteId = $(".tbl input:checkbox:checked").val();

        var checkLength = eval(cnt - cntall);

        if (checkLength >= "1") {
          $(".modal").fadeIn();
          $("#confirmModal").addClass("open");
        }
        return false;
      });
    });
    $(function () {
      $(document).on("click", ".allcheckbox", function () {
        $(this)
          .closest("table")
          .find("tbody :checkbox")
          .prop("checked", this.checked)
          .closest("tr")
          .toggleClass("selected", this.checked);
      });
      $("tbody :checkbox").on("click", function () {
        $(this).closest("tr").toggleClass("selected", this.checked);
        $(this)
          .closest("table")
          .find(".allchk")
          .prop(
            "checked",
            $(this).closest("table").find("tbody :checkbox:checked").length ==
              $(this).closest("table").find("tbody :checkbox").length
          );
      });
    });

    $(function () {
      $(document)
        .on("click", "input:checkbox", function () {
          vueThis.deleteId = $(this).val();

          var cntall = $(".allchk:checked").length;
          var cnt = $(".tbl input:checkbox:checked").length;

          // if()
          $(".selectednum").text(cnt - cntall + "件");
          if (cnt == 0) {
            $(".bulktool").removeClass("open");
            $(".command_wrap_inbox").removeClass("hide");
          } else {
            $(".bulktool").addClass("open");
            $(".command_wrap_inbox").addClass("hide");
          }
        })
        .trigger("change");
    });
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      new ScrollHint(".js-scrollable", {
        suggestiveShadow: true,
        i18n: {
          scrollable: "スクロールできます",
        },
      });
    });
    $(function () {
      vueThis.publishedStartValidated = false;
      vueThis.publishedEndValidated = false;
      // ボックスを表示する
      $(".form_dsn").on("click", function (e) {
        $(".megamenu").fadeIn("fast");
      });

      // ボックス内をクリックした際ボックスを非表示にしない
      $(".megamenu").on("click", function (event) {
        event.stopPropagation();
      });

      // ボックス外をクリックした際ボックスを非表示にする
      // ボックス外をクリックした際ボックスを非表示にする
      $(document).on("click", function (e) {
        if (e.target.className !== "megamenu" && e.target.className !== "form_dsn") {
          if (
            e.target.className ==
              "ui-datepicker-prev ui-corner-all ui-state-hover ui-datepicker-prev-hover" ||
            e.target.className == "ui-icon ui-icon-circle-triangle-w"
          ) {
            return false;
          }
          if (
            e.target.className ==
              "ui-datepicker-next ui-corner-all ui-state-hover ui-datepicker-next-hover" ||
            e.target.className == "ui-icon ui-icon-circle-triangle-e"
          ) {
            return false;
          }

          $(".megamenu").fadeOut("fast");
        }
      });
    });
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
@import "~@/assets/front_component/js/timepicker/datepicker.css";
</style>
